import { getPermissionsManagerApiGatewayDomain } from './getPermissionsManagerAPI';

const PERMISSIONS_TOOL_URL = getPermissionsManagerApiGatewayDomain();

const HEADER_LINKS_BY_ROLE = {
    learner: [
        {
            id: '/learner/performance',
            route: '/learner/performance',
            label: 'Your Performance'
        }
    ],
    trainer: []
}
const HELP_LINKS = [
    {
        url: 'https://amazon.awsapps.com/workdocs-amazon/index.html#/document/1067f20e2840e9c05741c0a7112ca02ffba52632650e59de004caab91082f221',
        label: 'User Guide'
    },
    {
        url: 'https://river.amazon.com/workflow/HYD16/run/wfss-7f19e2ab-6360-41e0-aba8-c1403be094ee/?buildingType=ctps_learning&q0=47e59897-2707-47af-b0c9-0a8f035d97ab&q1=&q2=&q3=&q4=',
        label: 'Create a Ticket'
    }
]
const LEARNER_HOW_TO_INSTRUCTIONS = [
    'If "View Case" button is available, click to open case. Otherwise you will need to open Paragon/Nautilus, and enter the Case ID manually.',
    'Read through case information.',
    'Return to LITT click "Start Practice" button to answer the questions here.',
    'Once you are done, view your results and compare w/ correct answer.',
    'Then, return to your session and discuss with your trainer.'
]
const LEARNER_PERFORMANCE_TABLE_HEADERS = [
    'Task ID',
    'Your Action',
    'Trainer Action',
    'Case Status',
    'Trainer Feedback',
    'Score',
    'Actions'
]

const isProdStage = window.location.href.includes('prod')
console.log('is prod stage', isProdStage)
const API_BASE_PATH = `https://api.${ isProdStage ? 'prod' : 'beta' }.litt.learning.ctps.a2z.com/jwt/`

export {
    API_BASE_PATH,
    PERMISSIONS_TOOL_URL,
    HEADER_LINKS_BY_ROLE,
    HELP_LINKS,
    LEARNER_HOW_TO_INSTRUCTIONS,
    LEARNER_PERFORMANCE_TABLE_HEADERS
}
