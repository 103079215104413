import axios from 'axios';
import { getMidwayJwtToken } from '../auth/MidwayJwtToken'
import { API_BASE_PATH } from './constants'
import { PERMISSIONS_TOOL_URL } from '../utils/constants'
import { unmarshall } from "@aws-sdk/util-dynamodb";

const apiCaller = axios.create({
    baseURL: API_BASE_PATH
})

apiCaller.interceptors.request.use(async (config) => {
    config.headers["Authorization"] = `Bearer ${await getMidwayJwtToken()}`;
    config.headers["Content-Type"] = "application/json"
    return config;
})

const createPracticeCasesForCohort = async (params: any) => {
    try {
        const cohortDetails = await axios.get(`${PERMISSIONS_TOOL_URL}/cohorts/byid/${params.cohortId}`, { withCredentials: true });
        const logins = cohortDetails.data.Items[0];
        if (!logins) {
            throw new Error(`No logins found for cohort ${params.cohortId}`);
        }        
        const unmarshalledLogins = unmarshall(logins);
        const { aliases } = unmarshalledLogins;

        const practiceCase = {
        createdBy: params.createdBy,
        cohortId: params.cohortId,
        org: params.org,
        skill: params.skill,
        practiceCaseId: params.practiceCaseId,
        caseUrl: params.caseUrl,
        templateId: params.templateId,
        cohortDetails: {
            aliases
        }
        };
        const response = await apiCaller.post('/practice-cases', practiceCase);
        return response;
    } catch (error) {
        console.error('Error creating practice cases:', error);
        throw error;
    }
};
  

const updatePracticeCase = async (params: any) => {
    return apiCaller.patch(`/practice-cases`, params)
}

const updatePracticeCaseFeedback = async (params: any) => {
    return apiCaller.patch(`/practice-cases/feedback`, params)
}

export {
    createPracticeCasesForCohort,
    updatePracticeCase,
    updatePracticeCaseFeedback
}

export default apiCaller
